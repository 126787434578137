function InfoCardController($mdDialog, $filter, $scope, $log, jobTracker, User,
  userProfile, jsyaml, searchQuery) {
  this.deleting = false;
  this.deletionSuccess = false;
  this.deletionErr = '';

  this.numSamples = 0;

  this.jobInputQuery = null;
  this.searchConfigYaml = null;

  this.profile = userProfile;

  this.config = null;

  this.$onChanges = (cObject) => {
    if (cObject.job && cObject.job.currentValue) {
      this.numSamples = cObject.job.currentValue.getNumSamples();
      this.config = cObject.job.currentValue.config ? JSON.parse(cObject.job.currentValue.config) : null;

      this.inputQueryConfig = null;
      this.searchConfigYaml = null;
      if (cObject.job.currentValue.inputQueryConfig && cObject.job.currentValue.inputQueryConfig.queryBody) {
        if (typeof cObject.job.currentValue.inputQueryConfig.queryBody === 'string') {
          this.inputQueryConfig = JSON.parse(cObject.job.currentValue.inputQueryConfig.queryBody);

          this.jobInputQuery = searchQuery.getQuery(this.inputQueryConfig);
        } else {
          this.inputQueryConfig = cObject.job.currentValue.inputQueryConfig.queryBody;

          this.jobInputQuery = searchQuery.getQuery(this.inputQueryConfig);
        }
      }
    }
  };

  //Memoized time getting function
  const attemptTime = {};
  this.getTime = (type, attempt, start, end) => {
    if (attemptTime[type] && attemptTime[type][attempt]) {
      return attemptTime[type][attempt];
    }

    if (!attemptTime[type]) {
      attemptTime[type] = {};
    }

    attemptTime[type][attempt] = $filter('msToTime')(new Date(end).getTime() - new Date(start).getTime());

    return attemptTime[type][attempt];
  };

  this.updating = false;
  this.updateSuccess = false;
  this.updateErr = false;

  this.updateJob = (type, prop, val, ev) => {
    if (type === undefined) {
      type = 'update';
    }

    if (type !== 'update' && type !== 'delete') {
      this.updateErr = new Error("Type must be one of 'update' or 'delete'");
      return;
    }

    if (prop == 'visibility') {
      const confirm = $mdDialog.confirm()
        .title('Make this job ' + val + '?')
        .textContent(val === 'public' ? 'This job will be visible to the world under the "Public" tab' : 'This job will be visible only to this account')
        .ariaLabel('Change privacy settings')
        .targetEvent(ev)
        .ok('Make ' + val)
        .cancel('Cancel');

      $mdDialog.show(confirm).then(() => _updateJob(prop, val), () => {});

      return;
    }

    return _updateJob(prop, val);
  };

  const _updateJob = (prop, val) => {
    this.updating = true;

    const updateProp = {};
    updateProp[prop] = val;

    jobTracker.patch(this.job, updateProp).then((updatedJob) => {
      this.updateSuccess = true;
      this.onUpdated();
    }, (err) => {
      this.updateErr = err;
    }).finally(() => {
      this.updating = false;

      // Don't clear errors
      // TODO: handle clearing errors on click
    });
  };

  this.deleteJob = ($event) => {
    this.deleting = true;

    // Notify consumers that the job deletion was handled here, to avoid
    // unnecessary dialogs.
    this.onDeleting();

    $event.stopImmediatePropagation();
    this.job.$remove((job) => {
      // This isn't strictly necessary; jobs.events.service should pick this up
      // It is more of a precaution against socket.io failure
      // In any case jobTracker additions are idempotent, so no big deal
      [this.err, this.job] = jobTracker.trackJobUpdate(job);

      this.deletionSuccess = true;
      this.onDeleted();
    }, (err) => {
      this.deletionErr = err;
    }).finally(() => {
      this.deleting = false;

      // Don't clear errors
      // TODO: handle clearing errors on click
    });
  };

  this.showFullSearchConfig = ($event) => {
    if (!this.config) {
      $log.warn("No config to download");
      return;
    }

    if (!this.searchConfigYaml) {
      this.searchConfigYaml = jsyaml.safeDump(this.inputQueryConfig, {
        sortKeys: true,
        noRefs: true,
        noCompatMode: true,
      });
    }

    // Appending dialog to document.body to cover sidenav in docs app
    // Modal dialogs should fully cover application
    // to prevent interaction outside of dialog
    $mdDialog.show({
      parent: angular.element(document.querySelector('#deleted-popup')),
      template: `<md-dialog aria-label='Search Config' flex='80' style='max-width:50%; max-height:70%'>
            <md-toolbar>
              <div class='md-toolbar-tools'>
                <h1>Search Configuration</h1>
                <span flex></span>
                <md-button
                aria-label='Close' class='md-icon-button'
                ng-click='cancel()'>
                  <md-icon class='material-icons'>close</md-icon>
                </md-button>
              </div>
            </md-toolbar>
            <md-dialog-content>
              <div class='md-dialog-content' layout='column'>
                <div style='white-space: pre-wrap;'>
                  {{searchConfig}}
                 <div>
              </div>
            </md-dialog-content>
          </md-dialog>
         `,
      locals: {
        searchConfig: this.searchConfigYaml,
      },
      controller: function dialogController($scope, $mdDialog, searchConfig) {
        $scope.searchConfig = searchConfig;

        $scope.cancel = $mdDialog.hide;
      },
    });
  };

  this.downloadYamlConfig = () => {
    if (!this.config) {
      $log.warn("No config to download");
      return;
    }

    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(jsyaml.safeDump(this.config, {
      sortKeys: true,
      noRefs: true,
      noCompatMode: true,
    })));
    element.setAttribute('download', `${this.config.name || this.config.assembly}.v${this.config.version}.yaml`);
    element.target = '_self'; //may be needed for firefox
    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  };

  this.showUsers = () => {
    this.loadingUsers = true;

    User.getAllUsers().$promise.then((users) => {
      console.info('got users', users);
    }).catch((err) => {
      $log.error(err);
    }).finally(() => {
      this.loadingUsers = false;
    });
  };

  // this.showUsers();

  // function _showAlert(job) {
  //   // Appending dialog to document.body to cover sidenav in docs app
  //   // Modal dialogs should fully cover application
  //   // to prevent interaction outside of dialog
  //   $mdDialog.show(
  //     $mdDialog.alert()
  //     .parent(angular.element(document.querySelector('#deleted-popup')))
  //     .clickOutsideToClose(true)
  //     .title(`This job was deleted`)
  //     .textContent('(In another active session)')
  //     .ariaLabel('Job deleted dialog')
  //     .ok('Ok!')
  //   );
  // }
}

angular.module('sq.jobs.infoCard.component', [
    'sq.user.model',
    'sq.jobs.tracker.service',
    'sq.jobs.events.service',
    'sq.user.profile.service',
    'sq.jobs.results.search.query.service'
  ])
  .component('sqJobInfoCard', {
    bindings: {
      // The submission object (could be search or main job submission)
      job: '<',
      onBack: '&',
      cardWidth: '@',
      onDeleting: '&',
      onDeleted: '&',
      onUpdated: '&',
    }, // isolate scope
    templateUrl: 'jobs/jobs.infoCard.tpl.html',
    controller: InfoCardController,
    controllerAs: '$ctrl',
    transclude: {
      headerActions: '?headerActions',
      cardContent: '?cardContent',
      menuOptions: '?menuOptions',
      // bottomRight : '?bottomRight',
    },
  });